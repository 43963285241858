"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.OAuth = exports.JST = void 0;
const web3_js_1 = require("@solana/web3.js");
const bs58_1 = require("bs58");
const tweetnacl_1 = require("tweetnacl");
const uuid_1 = require("uuid");
const MONTH = 30 * 24 * 60 * 60;
class JST {
    /**
     * Json Solana Token
     * @param opts
     * @param opts.id Unique ID
     * @param opts.issuer We recommend to use your domain .e.g, hub.sentre.io
     * @param opts.createdDate JST created date (unix timestampt in seconds)
     * @param opts.ttl Time to Live in seconds
     */
    constructor({ id = JST.rand(), issuer, createdDate = JST.now(), ttl = MONTH, }) {
        this.concat = () => {
            return `${this.id}/${this.issuer}/${this.createdDate}/${this.ttl}`;
        };
        /**
         * Validate JST expiration
         * @returns `true` or `false`
         */
        this.isExpired = () => {
            return JST.now() > this.createdDate + this.ttl;
        };
        /**
         * Convert JST to buffer
         * @returns Buffer
         */
        this.toBuffer = () => {
            const str = this.concat();
            return Buffer.from(str, 'utf8');
        };
        if (id.includes('/') || issuer.includes('/'))
            throw new Error("The token's id and issuer cannot incluse backslash symbol.");
        this.id = id;
        this.issuer = issuer;
        this.createdDate = createdDate;
        this.ttl = ttl;
    }
}
exports.JST = JST;
/**
 * Generate a random universal unique id (uuid v4)
 * @returns uuid
 */
JST.rand = uuid_1.v4;
/**
 * Get the current date
 * @returns Unix timestamp in seconds
 */
JST.now = () => {
    return Math.floor(Date.now() / 1000);
};
/**
 * Infer JST from buffer
 * @param buf JST buffer
 * @returns JST
 */
JST.fromBuffer = (buf) => {
    const str = buf.toString();
    const [id, issuer, createdDate, ttl] = str.split('/');
    return new JST({
        id,
        issuer,
        createdDate: Number(createdDate),
        ttl: Number(ttl),
    });
};
class OAuth {
}
exports.OAuth = OAuth;
_a = OAuth;
OAuth.seed = (jst) => {
    const hex = Buffer.from((0, tweetnacl_1.hash)(jst.toBuffer())).toString('hex');
    const content = `Issuing JSON Solana Token to ${jst.issuer}: ${hex}`;
    return Buffer.from(new TextEncoder().encode(content));
};
/**
 * Conveniently issue an "unsigned" JST
 * @param issuer Issuer name. We recommend to use your app domain
 * @param ttl Time to Live (in seconds)
 * @returns A Json Solana Token
 */
OAuth.issue = (issuer, ttl = MONTH) => {
    return new JST({ issuer, ttl });
};
/**
 * Verify the bearer, which is returned by `sign`
 * @param bearer The bearer. To use, add to request header `Authorization: Bearer bearer`
 * @returns A object of { publicKey, signature, jst }
 */
OAuth.parse = (bearer) => {
    const [address, encodedSig, code] = bearer.split('/');
    const publicKey = new web3_js_1.PublicKey(address);
    const signature = (0, bs58_1.decode)(encodedSig);
    const jst = JST.fromBuffer(Buffer.from((0, bs58_1.decode)(code)));
    return { publicKey, signature, jst };
};
/**
 * Sign the JST
 * @param jst JST instance
 * @param signer The signer
 * @returns Bearer
 */
OAuth.sign = (jst, signer) => __awaiter(void 0, void 0, void 0, function* () {
    const publicKey = yield signer.getPublicKey();
    const address = publicKey.toBase58();
    const sig = yield signer.signMessage(OAuth.seed(jst));
    const encodedSig = (0, bs58_1.encode)(sig);
    const code = (0, bs58_1.encode)(jst.toBuffer());
    return `${address}/${encodedSig}/${code}`;
});
/**
 * Verify the bearer, which is returned by `sign`
 * @param bearer The bearer. To use, add to request header `Authorization: Bearer bearer`
 * @param strict If true, the validator will throw exception instead of boolean. Default: false
 * @returns `true` or `false`
 */
OAuth.verify = (bearer, strict = false) => {
    try {
        const { publicKey, signature, jst } = OAuth.parse(bearer);
        if (!publicKey)
            throw new Error('Broken public key');
        const ok = tweetnacl_1.sign.detached.verify(OAuth.seed(jst), Buffer.from(signature), publicKey.toBuffer());
        if (!ok)
            throw new Error('Invalid signature');
        if (jst.isExpired())
            throw new Error('Expired token');
        return true;
    }
    catch (er) {
        if (!strict)
            return false;
        else
            throw new Error(er.message);
    }
};
