// ../../../react-shim.js
import React from "react";

// src/form-control.tsx
import { createContext } from "@chakra-ui/react-context";
import { mergeRefs } from "@chakra-ui/react-use-merge-refs";
import {
  chakra,
  forwardRef,
  omitThemingProps,
  useMultiStyleConfig
} from "@chakra-ui/system";

// ../../utilities/shared-utils/dist/index.esm.js
var cx = (...classNames) => classNames.filter(Boolean).join(" ");
var dataAttr = (condition) => condition ? "" : void 0;
var ariaAttr = (condition) => condition ? true : void 0;
function callAllHandlers(...fns) {
  return function func(event) {
    fns.some((fn) => {
      fn == null ? void 0 : fn(event);
      return event == null ? void 0 : event.defaultPrevented;
    });
  };
}

// src/form-control.tsx
import { useCallback, useId, useState } from "react";
var [FormControlStylesProvider, useFormControlStyles] = createContext({
  name: `FormControlStylesContext`,
  errorMessage: `useFormControlStyles returned is 'undefined'. Seems you forgot to wrap the components in "<FormControl />" `
});
var [FormControlProvider, useFormControlContext] = createContext({
  strict: false,
  name: "FormControlContext"
});
function useFormControlProvider(props) {
  const {
    id: idProp,
    isRequired,
    isInvalid,
    isDisabled,
    isReadOnly,
    ...htmlProps
  } = props;
  const uuid = useId();
  const id = idProp || `field-${uuid}`;
  const labelId = `${id}-label`;
  const feedbackId = `${id}-feedback`;
  const helpTextId = `${id}-helptext`;
  const [hasFeedbackText, setHasFeedbackText] = useState(false);
  const [hasHelpText, setHasHelpText] = useState(false);
  const [isFocused, setFocus] = useState(false);
  const getHelpTextProps = useCallback((props2 = {}, forwardedRef = null) => ({
    id: helpTextId,
    ...props2,
    ref: mergeRefs(forwardedRef, (node) => {
      if (!node)
        return;
      setHasHelpText(true);
    })
  }), [helpTextId]);
  const getLabelProps = useCallback((props2 = {}, forwardedRef = null) => ({
    ...props2,
    ref: forwardedRef,
    "data-focus": dataAttr(isFocused),
    "data-disabled": dataAttr(isDisabled),
    "data-invalid": dataAttr(isInvalid),
    "data-readonly": dataAttr(isReadOnly),
    id: props2.id ?? labelId,
    htmlFor: props2.htmlFor ?? id
  }), [id, isDisabled, isFocused, isInvalid, isReadOnly, labelId]);
  const getErrorMessageProps = useCallback((props2 = {}, forwardedRef = null) => ({
    id: feedbackId,
    ...props2,
    ref: mergeRefs(forwardedRef, (node) => {
      if (!node)
        return;
      setHasFeedbackText(true);
    }),
    "aria-live": "polite"
  }), [feedbackId]);
  const getRootProps = useCallback((props2 = {}, forwardedRef = null) => ({
    ...props2,
    ...htmlProps,
    ref: forwardedRef,
    role: "group"
  }), [htmlProps]);
  const getRequiredIndicatorProps = useCallback((props2 = {}, forwardedRef = null) => ({
    ...props2,
    ref: forwardedRef,
    role: "presentation",
    "aria-hidden": true,
    children: props2.children || "*"
  }), []);
  return {
    isRequired: !!isRequired,
    isInvalid: !!isInvalid,
    isReadOnly: !!isReadOnly,
    isDisabled: !!isDisabled,
    isFocused: !!isFocused,
    onFocus: () => setFocus(true),
    onBlur: () => setFocus(false),
    hasFeedbackText,
    setHasFeedbackText,
    hasHelpText,
    setHasHelpText,
    id,
    labelId,
    feedbackId,
    helpTextId,
    htmlProps,
    getHelpTextProps,
    getErrorMessageProps,
    getRootProps,
    getLabelProps,
    getRequiredIndicatorProps
  };
}
var FormControl = forwardRef(function FormControl2(props, ref) {
  const styles = useMultiStyleConfig("Form", props);
  const ownProps = omitThemingProps(props);
  const {
    getRootProps,
    htmlProps: _,
    ...context
  } = useFormControlProvider(ownProps);
  const className = cx("chakra-form-control", props.className);
  return /* @__PURE__ */ React.createElement(FormControlProvider, {
    value: context
  }, /* @__PURE__ */ React.createElement(FormControlStylesProvider, {
    value: styles
  }, /* @__PURE__ */ React.createElement(chakra.div, {
    ...getRootProps({}, ref),
    className,
    __css: styles["container"]
  })));
});
FormControl.displayName = "FormControl";
var FormHelperText = forwardRef(function FormHelperText2(props, ref) {
  const field = useFormControlContext();
  const styles = useFormControlStyles();
  const className = cx("chakra-form__helper-text", props.className);
  return /* @__PURE__ */ React.createElement(chakra.div, {
    ...field == null ? void 0 : field.getHelpTextProps(props, ref),
    __css: styles.helperText,
    className
  });
});
FormHelperText.displayName = "FormHelperText";

// src/use-form-control.ts
function useFormControl(props) {
  const { isDisabled, isInvalid, isReadOnly, isRequired, ...rest } = useFormControlProps(props);
  return {
    ...rest,
    disabled: isDisabled,
    readOnly: isReadOnly,
    required: isRequired,
    "aria-invalid": ariaAttr(isInvalid),
    "aria-required": ariaAttr(isRequired),
    "aria-readonly": ariaAttr(isReadOnly)
  };
}
function useFormControlProps(props) {
  const field = useFormControlContext();
  const {
    id,
    disabled,
    readOnly,
    required,
    isRequired,
    isInvalid,
    isReadOnly,
    isDisabled,
    onFocus,
    onBlur,
    ...rest
  } = props;
  const labelIds = props["aria-describedby"] ? [props["aria-describedby"]] : [];
  if ((field == null ? void 0 : field.hasFeedbackText) && (field == null ? void 0 : field.isInvalid)) {
    labelIds.push(field.feedbackId);
  }
  if (field == null ? void 0 : field.hasHelpText) {
    labelIds.push(field.helpTextId);
  }
  return {
    ...rest,
    "aria-describedby": labelIds.join(" ") || void 0,
    id: id ?? (field == null ? void 0 : field.id),
    isDisabled: disabled ?? isDisabled ?? (field == null ? void 0 : field.isDisabled),
    isReadOnly: readOnly ?? isReadOnly ?? (field == null ? void 0 : field.isReadOnly),
    isRequired: required ?? isRequired ?? (field == null ? void 0 : field.isRequired),
    isInvalid: isInvalid ?? (field == null ? void 0 : field.isInvalid),
    onFocus: callAllHandlers(field == null ? void 0 : field.onFocus, onFocus),
    onBlur: callAllHandlers(field == null ? void 0 : field.onBlur, onBlur)
  };
}

// src/form-error.tsx
import { Icon } from "@chakra-ui/icon";
import { createContext as createContext2 } from "@chakra-ui/react-context";
import {
  chakra as chakra2,
  forwardRef as forwardRef2,
  omitThemingProps as omitThemingProps2,
  useMultiStyleConfig as useMultiStyleConfig2
} from "@chakra-ui/system";
var [FormErrorStylesProvider, useFormErrorStyles] = createContext2({
  name: `FormErrorStylesContext`,
  errorMessage: `useFormErrorStyles returned is 'undefined'. Seems you forgot to wrap the components in "<FormError />" `
});
var FormErrorMessage = forwardRef2((props, ref) => {
  const styles = useMultiStyleConfig2("FormError", props);
  const ownProps = omitThemingProps2(props);
  const field = useFormControlContext();
  if (!(field == null ? void 0 : field.isInvalid))
    return null;
  return /* @__PURE__ */ React.createElement(FormErrorStylesProvider, {
    value: styles
  }, /* @__PURE__ */ React.createElement(chakra2.div, {
    ...field == null ? void 0 : field.getErrorMessageProps(ownProps, ref),
    className: cx("chakra-form__error-message", props.className),
    __css: {
      display: "flex",
      alignItems: "center",
      ...styles.text
    }
  }));
});
FormErrorMessage.displayName = "FormErrorMessage";
var FormErrorIcon = forwardRef2((props, ref) => {
  const styles = useFormErrorStyles();
  const field = useFormControlContext();
  if (!(field == null ? void 0 : field.isInvalid))
    return null;
  const _className = cx("chakra-form__error-icon", props.className);
  return /* @__PURE__ */ React.createElement(Icon, {
    ref,
    "aria-hidden": true,
    ...props,
    __css: styles.icon,
    className: _className
  }, /* @__PURE__ */ React.createElement("path", {
    fill: "currentColor",
    d: "M11.983,0a12.206,12.206,0,0,0-8.51,3.653A11.8,11.8,0,0,0,0,12.207,11.779,11.779,0,0,0,11.8,24h.214A12.111,12.111,0,0,0,24,11.791h0A11.766,11.766,0,0,0,11.983,0ZM10.5,16.542a1.476,1.476,0,0,1,1.449-1.53h.027a1.527,1.527,0,0,1,1.523,1.47,1.475,1.475,0,0,1-1.449,1.53h-.027A1.529,1.529,0,0,1,10.5,16.542ZM11,12.5v-6a1,1,0,0,1,2,0v6a1,1,0,1,1-2,0Z"
  }));
});
FormErrorIcon.displayName = "FormErrorIcon";

// src/form-label.tsx
import {
  chakra as chakra3,
  forwardRef as forwardRef3,
  omitThemingProps as omitThemingProps3,
  useStyleConfig
} from "@chakra-ui/system";
var FormLabel = forwardRef3(function FormLabel2(passedProps, ref) {
  const styles = useStyleConfig("FormLabel", passedProps);
  const props = omitThemingProps3(passedProps);
  const {
    className,
    children,
    requiredIndicator = /* @__PURE__ */ React.createElement(RequiredIndicator, null),
    optionalIndicator = null,
    ...rest
  } = props;
  const field = useFormControlContext();
  const ownProps = (field == null ? void 0 : field.getLabelProps(rest, ref)) ?? { ref, ...rest };
  return /* @__PURE__ */ React.createElement(chakra3.label, {
    ...ownProps,
    className: cx("chakra-form__label", props.className),
    __css: {
      display: "block",
      textAlign: "start",
      ...styles
    }
  }, children, (field == null ? void 0 : field.isRequired) ? requiredIndicator : optionalIndicator);
});
FormLabel.displayName = "FormLabel";
var RequiredIndicator = forwardRef3(function RequiredIndicator2(props, ref) {
  const field = useFormControlContext();
  const styles = useFormControlStyles();
  if (!(field == null ? void 0 : field.isRequired))
    return null;
  const className = cx("chakra-form__required-indicator", props.className);
  return /* @__PURE__ */ React.createElement(chakra3.span, {
    ...field == null ? void 0 : field.getRequiredIndicatorProps(props, ref),
    __css: styles.requiredIndicator,
    className
  });
});
RequiredIndicator.displayName = "RequiredIndicator";
export {
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  RequiredIndicator,
  useFormControl,
  useFormControlContext,
  useFormControlProps,
  useFormControlStyles,
  useFormErrorStyles
};
