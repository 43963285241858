// src/index.ts
import { anatomy } from "@chakra-ui/anatomy";

// src/color.ts
import {
  TinyColor,
  readability,
  isReadable,
  random
} from "@ctrl/tinycolor";

// ../../../node_modules/.pnpm/dlv@1.1.3/node_modules/dlv/dist/dlv.es.js
function dlv_es_default(t, e, l, n, r) {
  for (e = e.split ? e.split(".") : e, n = 0; n < e.length; n++)
    t = t ? t[e[n]] : r;
  return t === r ? l : t;
}

// src/color.ts
var isEmptyObject = (obj) => Object.keys(obj).length === 0;
var getColor = (theme, color, fallback) => {
  const hex = dlv_es_default(theme, `colors.${color}`, color);
  const { isValid } = new TinyColor(hex);
  return isValid ? hex : fallback;
};
var tone = (color) => (theme) => {
  const hex = getColor(theme, color);
  const isDark2 = new TinyColor(hex).isDark();
  return isDark2 ? "dark" : "light";
};
var isDark = (color) => (theme) => tone(color)(theme) === "dark";
var isLight = (color) => (theme) => tone(color)(theme) === "light";
var transparentize = (color, opacity) => (theme) => {
  const raw = getColor(theme, color);
  return new TinyColor(raw).setAlpha(opacity).toRgbString();
};
var whiten = (color, amount) => (theme) => {
  const raw = getColor(theme, color);
  return new TinyColor(raw).mix("#fff", amount).toHexString();
};
var blacken = (color, amount) => (theme) => {
  const raw = getColor(theme, color);
  return new TinyColor(raw).mix("#000", amount).toHexString();
};
var darken = (color, amount) => (theme) => {
  const raw = getColor(theme, color);
  return new TinyColor(raw).darken(amount).toHexString();
};
var lighten = (color, amount) => (theme) => new TinyColor(getColor(theme, color)).lighten(amount).toHexString();
var contrast = (fg, bg) => (theme) => readability(getColor(theme, bg), getColor(theme, fg));
var isAccessible = (textColor, bgColor, options) => (theme) => isReadable(getColor(theme, bgColor), getColor(theme, textColor), options);
var complementary = (color) => (theme) => new TinyColor(getColor(theme, color)).complement().toHexString();
function generateStripe(size = "1rem", color = "rgba(255, 255, 255, 0.15)") {
  return {
    backgroundImage: `linear-gradient(
    45deg,
    ${color} 25%,
    transparent 25%,
    transparent 50%,
    ${color} 50%,
    ${color} 75%,
    transparent 75%,
    transparent
  )`,
    backgroundSize: `${size} ${size}`
  };
}
function randomColor(opts) {
  const fallback = random().toHexString();
  if (!opts || isEmptyObject(opts)) {
    return fallback;
  }
  if (opts.string && opts.colors) {
    return randomColorFromList(opts.string, opts.colors);
  }
  if (opts.string && !opts.colors) {
    return randomColorFromString(opts.string);
  }
  if (opts.colors && !opts.string) {
    return randomFromList(opts.colors);
  }
  return fallback;
}
function randomColorFromString(str) {
  let hash = 0;
  if (str.length === 0)
    return hash.toString();
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  let color = "#";
  for (let j = 0; j < 3; j += 1) {
    const value = hash >> j * 8 & 255;
    color += `00${value.toString(16)}`.substr(-2);
  }
  return color;
}
function randomColorFromList(str, list) {
  let index = 0;
  if (str.length === 0)
    return list[0];
  for (let i = 0; i < str.length; i += 1) {
    index = str.charCodeAt(i) + ((index << 5) - index);
    index = index & index;
  }
  index = (index % list.length + list.length) % list.length;
  return list[index];
}
function randomFromList(list) {
  return list[Math.floor(Math.random() * list.length)];
}

// src/component.ts
function mode(light, dark) {
  return (props) => props.colorMode === "dark" ? dark : light;
}
function orient(options) {
  const { orientation, vertical, horizontal } = options;
  if (!orientation)
    return {};
  return orientation === "vertical" ? vertical : horizontal;
}

// ../../utilities/shared-utils/dist/index.esm.js
function isDev() {
  return process.env.NODE_ENV !== "production";
}
function isObject(value) {
  const type = typeof value;
  return value != null && (type === "object" || type === "function") && !Array.isArray(value);
}
var warn = (options) => {
  const { condition, message } = options;
  if (condition && isDev()) {
    console.warn(message);
  }
};

// src/create-breakpoints.ts
var createBreakpoints = (config) => {
  warn({
    condition: true,
    message: [
      `[chakra-ui]: createBreakpoints(...) will be deprecated pretty soon`,
      `simply pass the breakpoints as an object. Remove the createBreakpoints(..) call`
    ].join("")
  });
  return { base: "0em", ...config };
};

// src/css-calc.ts
function toRef(operand) {
  if (isObject(operand) && operand.reference) {
    return operand.reference;
  }
  return String(operand);
}
var toExpr = (operator, ...operands) => operands.map(toRef).join(` ${operator} `).replace(/calc/g, "");
var add = (...operands) => `calc(${toExpr("+", ...operands)})`;
var subtract = (...operands) => `calc(${toExpr("-", ...operands)})`;
var multiply = (...operands) => `calc(${toExpr("*", ...operands)})`;
var divide = (...operands) => `calc(${toExpr("/", ...operands)})`;
var negate = (x) => {
  const value = toRef(x);
  if (value != null && !Number.isNaN(parseFloat(value))) {
    return String(value).startsWith("-") ? String(value).slice(1) : `-${value}`;
  }
  return multiply(value, -1);
};
var calc = Object.assign((x) => ({
  add: (...operands) => calc(add(x, ...operands)),
  subtract: (...operands) => calc(subtract(x, ...operands)),
  multiply: (...operands) => calc(multiply(x, ...operands)),
  divide: (...operands) => calc(divide(x, ...operands)),
  negate: () => calc(negate(x)),
  toString: () => x.toString()
}), {
  add,
  subtract,
  multiply,
  divide,
  negate
});

// src/css-var.ts
function isDecimal(value) {
  return !Number.isInteger(parseFloat(value.toString()));
}
function replaceWhiteSpace(value, replaceValue = "-") {
  return value.replace(/\s+/g, replaceValue);
}
function escape(value) {
  const valueStr = replaceWhiteSpace(value.toString());
  if (valueStr.includes("\\."))
    return value;
  return isDecimal(value) ? valueStr.replace(".", `\\.`) : value;
}
function addPrefix(value, prefix = "") {
  return [prefix, escape(value)].filter(Boolean).join("-");
}
function toVarRef(name, fallback) {
  return `var(${escape(name)}${fallback ? `, ${fallback}` : ""})`;
}
function toVar(value, prefix = "") {
  return `--${addPrefix(value, prefix)}`;
}
function cssVar(name, options) {
  const cssVariable = toVar(name, options == null ? void 0 : options.prefix);
  return {
    variable: cssVariable,
    reference: toVarRef(cssVariable, getFallback(options == null ? void 0 : options.fallback))
  };
}
function getFallback(fallback) {
  if (typeof fallback === "string")
    return fallback;
  return fallback == null ? void 0 : fallback.reference;
}
export {
  addPrefix,
  anatomy,
  blacken,
  calc,
  complementary,
  contrast,
  createBreakpoints,
  cssVar,
  darken,
  generateStripe,
  getColor,
  isAccessible,
  isDark,
  isDecimal,
  isLight,
  lighten,
  mode,
  orient,
  randomColor,
  toVar,
  toVarRef,
  tone,
  transparentize,
  whiten
};
