// ../../../react-shim.js
import React from "react";

// src/icon.tsx
import {
  chakra,
  forwardRef
} from "@chakra-ui/system";
import { cx } from "@chakra-ui/shared-utils";
var fallbackIcon = {
  path: /* @__PURE__ */ React.createElement("g", {
    stroke: "currentColor",
    strokeWidth: "1.5"
  }, /* @__PURE__ */ React.createElement("path", {
    strokeLinecap: "round",
    fill: "none",
    d: "M9,9a3,3,0,1,1,4,2.829,1.5,1.5,0,0,0-1,1.415V14.25"
  }), /* @__PURE__ */ React.createElement("path", {
    fill: "currentColor",
    strokeLinecap: "round",
    d: "M12,17.25a.375.375,0,1,0,.375.375A.375.375,0,0,0,12,17.25h0"
  }), /* @__PURE__ */ React.createElement("circle", {
    fill: "none",
    strokeMiterlimit: "10",
    cx: "12",
    cy: "12",
    r: "11.25"
  })),
  viewBox: "0 0 24 24"
};
var Icon = forwardRef((props, ref) => {
  const {
    as: element,
    viewBox,
    color = "currentColor",
    focusable = false,
    children,
    className,
    __css,
    ...rest
  } = props;
  const _className = cx("chakra-icon", className);
  const styles = {
    w: "1em",
    h: "1em",
    display: "inline-block",
    lineHeight: "1em",
    flexShrink: 0,
    color,
    ...__css
  };
  const shared = {
    ref,
    focusable,
    className: _className,
    __css: styles
  };
  const _viewBox = viewBox ?? fallbackIcon.viewBox;
  if (element && typeof element !== "string") {
    return /* @__PURE__ */ React.createElement(chakra.svg, {
      as: element,
      ...shared,
      ...rest
    });
  }
  const _path = children ?? fallbackIcon.path;
  return /* @__PURE__ */ React.createElement(chakra.svg, {
    verticalAlign: "middle",
    viewBox: _viewBox,
    ...shared,
    ...rest
  }, _path);
});
Icon.displayName = "Icon";
var icon_default = Icon;

// src/create-icon.tsx
import { forwardRef as forwardRef2 } from "@chakra-ui/system";
import { Children } from "react";
function createIcon(options) {
  const {
    viewBox = "0 0 24 24",
    d: pathDefinition,
    displayName,
    defaultProps = {}
  } = options;
  const path = Children.toArray(options.path);
  const Comp = forwardRef2((props, ref) => /* @__PURE__ */ React.createElement(Icon, {
    ref,
    viewBox,
    ...defaultProps,
    ...props
  }, path.length ? path : /* @__PURE__ */ React.createElement("path", {
    fill: "currentColor",
    d: pathDefinition
  })));
  Comp.displayName = displayName;
  return Comp;
}
export {
  Icon,
  createIcon,
  icon_default as default
};
