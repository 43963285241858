"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Gateway = void 0;
const web3_js_1 = require("@solana/web3.js");
const bridge_1 = require("./bridge");
const constants_1 = require("./constants");
class Gateway {
    constructor(wallet, verbose = false) {
        this.terminate = () => this.messenger.killAll();
        this.emit = (id, data) => {
            const child = document.getElementById(id);
            if (!child || child.tagName !== 'IFRAME')
                throw new Error(`Cannot find iframe with id ${id}`);
            const wd = child === null || child === void 0 ? void 0 : child.contentWindow;
            if (!wd)
                throw new Error('Cannot access to iframe window');
            return this.messenger.emit(wd, data);
        };
        this.onConnect = (id, uid) => {
            return this.emit(id, { uid, event: constants_1.EVENTS.CONNECT, data: true });
        };
        this.onGetAddress = (id, uid) => __awaiter(this, void 0, void 0, function* () {
            const address = yield this.wallet.getAddress();
            return this.emit(id, { uid, event: constants_1.EVENTS.GET_ADDRESS, data: address });
        });
        this.onSignTransaction = (id, uid, buf) => __awaiter(this, void 0, void 0, function* () {
            const tx = web3_js_1.Transaction.from(buf);
            const signedTx = yield this.wallet.signTransaction(tx);
            const serializedTx = signedTx.serialize();
            return this.emit(id, {
                uid,
                event: constants_1.EVENTS.SIGN_TRANSACTION,
                data: serializedTx,
            });
        });
        this.onSignAllTransactions = (id, uid, bufs) => __awaiter(this, void 0, void 0, function* () {
            const txs = bufs.map((buf) => web3_js_1.Transaction.from(buf));
            const signedTxs = yield this.wallet.signAllTransactions(txs);
            const serializedTxs = signedTxs.map((signedTx) => signedTx.serialize());
            return this.emit(id, {
                uid,
                event: constants_1.EVENTS.SIGN_ALL_TRANSACTIONS,
                data: serializedTxs,
            });
        });
        this.onSignMessage = (id, uid, msg) => __awaiter(this, void 0, void 0, function* () {
            const signedMsg = yield this.wallet.signMessage(msg);
            return this.emit(id, {
                uid,
                event: constants_1.EVENTS.SIGN_MESSAGE,
                data: signedMsg,
            });
        });
        this.messenger = new bridge_1.Messenger({ name: 'gateway', verbose });
        this.wallet = wallet;
        this.messenger.listen(({ id, uid, event, data }) => __awaiter(this, void 0, void 0, function* () {
            if (event === constants_1.EVENTS.CONNECT)
                return this.onConnect(id, uid);
            if (event === constants_1.EVENTS.GET_ADDRESS)
                return this.onGetAddress(id, uid);
            if (event === constants_1.EVENTS.SIGN_TRANSACTION)
                return this.onSignTransaction(id, uid, data);
            if (event === constants_1.EVENTS.SIGN_ALL_TRANSACTIONS)
                return this.onSignAllTransactions(id, uid, data);
            if (event === constants_1.EVENTS.SIGN_MESSAGE)
                return this.onSignMessage(id, uid, data);
        }));
    }
}
exports.Gateway = Gateway;
