"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Messenger = void 0;
const CODE = 'sentre';
class Messenger {
    constructor({ name = 'unknown', verbose = false, }) {
        this.emit = (win, data) => {
            return win.postMessage({ type: CODE, payload: data }, '*');
        };
        this.listen = (callback) => {
            const handler = ({ data: { type, payload }, origin }) => {
                if (type === CODE) {
                    if (this.verbose)
                        console.log(this.name, origin, payload);
                    return callback(payload);
                }
            };
            window.addEventListener('message', handler);
            const kill = () => window.removeEventListener('message', handler);
            this.killers.push(kill);
            return kill;
        };
        this.killAll = () => {
            while (this.killers.length > 0) {
                const kill = this.killers.pop();
                if (kill)
                    kill();
            }
        };
        this.name = name;
        this.verbose = verbose;
        this.killers = [];
    }
}
exports.Messenger = Messenger;
