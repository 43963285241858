// ../../../react-shim.js
import React from "react";

// src/modal.tsx
import { Portal } from "@chakra-ui/portal";
import { createContext } from "@chakra-ui/react-context";
import {
  useMultiStyleConfig
} from "@chakra-ui/system";
import { AnimatePresence } from "framer-motion";

// ../../utilities/shared-utils/dist/index.esm.js
var cx = (...classNames) => classNames.filter(Boolean).join(" ");
function callAllHandlers(...fns) {
  return function func(event) {
    fns.some((fn) => {
      fn == null ? void 0 : fn(event);
      return event == null ? void 0 : event.defaultPrevented;
    });
  };
}

// src/use-modal.ts
import { mergeRefs } from "@chakra-ui/react-use-merge-refs";
import { hideOthers } from "aria-hidden";
import { useCallback, useEffect as useEffect2, useId, useMemo, useRef, useState } from "react";

// src/modal-manager.ts
import { useEffect } from "react";
var ModalManager = class {
  modals;
  constructor() {
    this.modals = [];
  }
  add(modal) {
    this.modals.push(modal);
  }
  remove(modal) {
    this.modals = this.modals.filter((_modal) => _modal !== modal);
  }
  isTopModal(modal) {
    const topmostModal = this.modals[this.modals.length - 1];
    return topmostModal === modal;
  }
};
var manager = new ModalManager();
function useModalManager(ref, isOpen) {
  useEffect(() => {
    if (isOpen) {
      manager.add(ref);
    }
    return () => {
      manager.remove(ref);
    };
  }, [isOpen, ref]);
}

// src/use-modal.ts
function useModal(props) {
  const {
    isOpen,
    onClose,
    id,
    closeOnOverlayClick = true,
    closeOnEsc = true,
    useInert = true,
    onOverlayClick: onOverlayClickProp,
    onEsc
  } = props;
  const dialogRef = useRef(null);
  const overlayRef = useRef(null);
  const [dialogId, headerId, bodyId] = useIds(id, `chakra-modal`, `chakra-modal--header`, `chakra-modal--body`);
  useAriaHidden(dialogRef, isOpen && useInert);
  useModalManager(dialogRef, isOpen);
  const mouseDownTarget = useRef(null);
  const onMouseDown = useCallback((event) => {
    mouseDownTarget.current = event.target;
  }, []);
  const onKeyDown = useCallback((event) => {
    if (event.key === "Escape") {
      event.stopPropagation();
      if (closeOnEsc) {
        onClose == null ? void 0 : onClose();
      }
      onEsc == null ? void 0 : onEsc();
    }
  }, [closeOnEsc, onClose, onEsc]);
  const [headerMounted, setHeaderMounted] = useState(false);
  const [bodyMounted, setBodyMounted] = useState(false);
  const getDialogProps = useCallback((props2 = {}, ref = null) => ({
    role: "dialog",
    ...props2,
    ref: mergeRefs(ref, dialogRef),
    id: dialogId,
    tabIndex: -1,
    "aria-modal": true,
    "aria-labelledby": headerMounted ? headerId : void 0,
    "aria-describedby": bodyMounted ? bodyId : void 0,
    onClick: callAllHandlers(props2.onClick, (event) => event.stopPropagation())
  }), [bodyId, bodyMounted, dialogId, headerId, headerMounted]);
  const onOverlayClick = useCallback((event) => {
    event.stopPropagation();
    if (mouseDownTarget.current !== event.target)
      return;
    if (!manager.isTopModal(dialogRef))
      return;
    if (closeOnOverlayClick) {
      onClose == null ? void 0 : onClose();
    }
    onOverlayClickProp == null ? void 0 : onOverlayClickProp();
  }, [onClose, closeOnOverlayClick, onOverlayClickProp]);
  const getDialogContainerProps = useCallback((props2 = {}, ref = null) => ({
    ...props2,
    ref: mergeRefs(ref, overlayRef),
    onClick: callAllHandlers(props2.onClick, onOverlayClick),
    onKeyDown: callAllHandlers(props2.onKeyDown, onKeyDown),
    onMouseDown: callAllHandlers(props2.onMouseDown, onMouseDown)
  }), [onKeyDown, onMouseDown, onOverlayClick]);
  return {
    isOpen,
    onClose,
    headerId,
    bodyId,
    setBodyMounted,
    setHeaderMounted,
    dialogRef,
    overlayRef,
    getDialogProps,
    getDialogContainerProps
  };
}
function useAriaHidden(ref, shouldHide) {
  const currentElement = ref.current;
  useEffect2(() => {
    if (!ref.current || !shouldHide)
      return void 0;
    return hideOthers(ref.current);
  }, [shouldHide, ref, currentElement]);
}
function useIds(idProp, ...prefixes) {
  const reactId = useId();
  const id = idProp || reactId;
  return useMemo(() => {
    return prefixes.map((prefix) => `${prefix}-${id}`);
  }, [id, prefixes]);
}

// src/modal.tsx
var [ModalStylesProvider, useModalStyles] = createContext({
  name: `ModalStylesContext`,
  errorMessage: `useModalStyles returned is 'undefined'. Seems you forgot to wrap the components in "<Modal />" `
});
var [ModalContextProvider, useModalContext] = createContext({
  strict: true,
  name: "ModalContext",
  errorMessage: "useModalContext: `context` is undefined. Seems you forgot to wrap modal components in `<Modal />`"
});
var Modal = (props) => {
  const {
    portalProps,
    children,
    autoFocus,
    trapFocus,
    initialFocusRef,
    finalFocusRef,
    returnFocusOnClose,
    blockScrollOnMount,
    allowPinchZoom,
    preserveScrollBarGap,
    motionPreset,
    lockFocusAcrossFrames,
    onCloseComplete
  } = props;
  const styles = useMultiStyleConfig("Modal", props);
  const modal = useModal(props);
  const context = {
    ...modal,
    autoFocus,
    trapFocus,
    initialFocusRef,
    finalFocusRef,
    returnFocusOnClose,
    blockScrollOnMount,
    allowPinchZoom,
    preserveScrollBarGap,
    motionPreset,
    lockFocusAcrossFrames
  };
  return /* @__PURE__ */ React.createElement(ModalContextProvider, {
    value: context
  }, /* @__PURE__ */ React.createElement(ModalStylesProvider, {
    value: styles
  }, /* @__PURE__ */ React.createElement(AnimatePresence, {
    onExitComplete: onCloseComplete
  }, context.isOpen && /* @__PURE__ */ React.createElement(Portal, {
    ...portalProps
  }, children))));
};
Modal.defaultProps = {
  lockFocusAcrossFrames: true,
  returnFocusOnClose: true,
  scrollBehavior: "outside",
  trapFocus: true,
  autoFocus: true,
  blockScrollOnMount: true,
  allowPinchZoom: false,
  motionPreset: "scale"
};
Modal.displayName = "Modal";

// src/modal-body.tsx
import { chakra, forwardRef } from "@chakra-ui/system";
import { useEffect as useEffect3 } from "react";
var ModalBody = forwardRef((props, ref) => {
  const { className, ...rest } = props;
  const { bodyId, setBodyMounted } = useModalContext();
  useEffect3(() => {
    setBodyMounted(true);
    return () => setBodyMounted(false);
  }, [setBodyMounted]);
  const _className = cx("chakra-modal__body", className);
  const styles = useModalStyles();
  return /* @__PURE__ */ React.createElement(chakra.div, {
    ref,
    className: _className,
    id: bodyId,
    ...rest,
    __css: styles.body
  });
});
ModalBody.displayName = "ModalBody";

// src/modal-close-button.tsx
import { CloseButton } from "@chakra-ui/close-button";
import { forwardRef as forwardRef2 } from "@chakra-ui/system";
var ModalCloseButton = forwardRef2((props, ref) => {
  const { onClick, className, ...rest } = props;
  const { onClose } = useModalContext();
  const _className = cx("chakra-modal__close-btn", className);
  const styles = useModalStyles();
  return /* @__PURE__ */ React.createElement(CloseButton, {
    ref,
    __css: styles.closeButton,
    className: _className,
    onClick: callAllHandlers(onClick, (event) => {
      event.stopPropagation();
      onClose();
    }),
    ...rest
  });
});
ModalCloseButton.displayName = "ModalCloseButton";

// src/modal-content.tsx
import {
  chakra as chakra3,
  forwardRef as forwardRef4
} from "@chakra-ui/system";

// src/modal-focus.tsx
import { FocusLock } from "@chakra-ui/focus-lock";
import { usePresence } from "framer-motion";
import { useEffect as useEffect4 } from "react";
import { RemoveScroll } from "react-remove-scroll";
function ModalFocusScope(props) {
  const {
    autoFocus,
    trapFocus,
    dialogRef,
    initialFocusRef,
    blockScrollOnMount,
    allowPinchZoom,
    finalFocusRef,
    returnFocusOnClose,
    preserveScrollBarGap,
    lockFocusAcrossFrames
  } = useModalContext();
  const [isPresent, safeToRemove] = usePresence();
  useEffect4(() => {
    if (!isPresent && safeToRemove) {
      setTimeout(safeToRemove);
    }
  }, [isPresent, safeToRemove]);
  return /* @__PURE__ */ React.createElement(FocusLock, {
    autoFocus,
    isDisabled: !trapFocus,
    initialFocusRef,
    finalFocusRef,
    restoreFocus: returnFocusOnClose,
    contentRef: dialogRef,
    lockFocusAcrossFrames
  }, /* @__PURE__ */ React.createElement(RemoveScroll, {
    removeScrollBar: !preserveScrollBarGap,
    allowPinchZoom,
    enabled: blockScrollOnMount,
    forwardProps: true
  }, props.children));
}

// src/modal-transition.tsx
import { chakra as chakra2 } from "@chakra-ui/system";
import { scaleFadeConfig, slideFadeConfig } from "@chakra-ui/transition";
import { motion } from "framer-motion";
import { forwardRef as forwardRef3 } from "react";
var transitions = {
  slideInBottom: {
    ...slideFadeConfig,
    custom: { offsetY: 16, reverse: true }
  },
  slideInRight: {
    ...slideFadeConfig,
    custom: { offsetX: 16, reverse: true }
  },
  scale: {
    ...scaleFadeConfig,
    custom: { initialScale: 0.95, reverse: true }
  },
  none: {}
};
var MotionSection = chakra2(motion.section);
var getMotionProps = (preset) => {
  return transitions[preset || "none"];
};
var ModalTransition = forwardRef3((props, ref) => {
  const { preset, motionProps = getMotionProps(preset), ...rest } = props;
  return /* @__PURE__ */ React.createElement(MotionSection, {
    ref,
    ...motionProps,
    ...rest
  });
});
ModalTransition.displayName = "ModalTransition";

// src/modal-content.tsx
var ModalContent = forwardRef4((props, ref) => {
  const {
    className,
    children,
    containerProps: rootProps,
    motionProps,
    ...rest
  } = props;
  const { getDialogProps, getDialogContainerProps } = useModalContext();
  const dialogProps = getDialogProps(rest, ref);
  const containerProps = getDialogContainerProps(rootProps);
  const _className = cx("chakra-modal__content", className);
  const styles = useModalStyles();
  const dialogStyles = {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: "100%",
    outline: 0,
    ...styles.dialog
  };
  const dialogContainerStyles = {
    display: "flex",
    width: "100vw",
    height: "$100vh",
    position: "fixed",
    left: 0,
    top: 0,
    ...styles.dialogContainer
  };
  const { motionPreset } = useModalContext();
  return /* @__PURE__ */ React.createElement(ModalFocusScope, null, /* @__PURE__ */ React.createElement(chakra3.div, {
    ...containerProps,
    className: "chakra-modal__content-container",
    tabIndex: -1,
    __css: dialogContainerStyles
  }, /* @__PURE__ */ React.createElement(ModalTransition, {
    preset: motionPreset,
    motionProps,
    className: _className,
    ...dialogProps,
    __css: dialogStyles
  }, children)));
});
ModalContent.displayName = "ModalContent";

// src/modal-footer.tsx
import {
  chakra as chakra4,
  forwardRef as forwardRef5
} from "@chakra-ui/system";
var ModalFooter = forwardRef5((props, ref) => {
  const { className, ...rest } = props;
  const _className = cx("chakra-modal__footer", className);
  const styles = useModalStyles();
  const footerStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    ...styles.footer
  };
  return /* @__PURE__ */ React.createElement(chakra4.footer, {
    ref,
    ...rest,
    __css: footerStyles,
    className: _className
  });
});
ModalFooter.displayName = "ModalFooter";

// src/modal-header.tsx
import {
  chakra as chakra5,
  forwardRef as forwardRef6
} from "@chakra-ui/system";
import { useEffect as useEffect5 } from "react";
var ModalHeader = forwardRef6((props, ref) => {
  const { className, ...rest } = props;
  const { headerId, setHeaderMounted } = useModalContext();
  useEffect5(() => {
    setHeaderMounted(true);
    return () => setHeaderMounted(false);
  }, [setHeaderMounted]);
  const _className = cx("chakra-modal__header", className);
  const styles = useModalStyles();
  const headerStyles = {
    flex: 0,
    ...styles.header
  };
  return /* @__PURE__ */ React.createElement(chakra5.header, {
    ref,
    className: _className,
    id: headerId,
    ...rest,
    __css: headerStyles
  });
});
ModalHeader.displayName = "ModalHeader";

// src/modal-overlay.tsx
import {
  chakra as chakra6,
  forwardRef as forwardRef7
} from "@chakra-ui/system";
import { fadeConfig } from "@chakra-ui/transition";
import { motion as motion2 } from "framer-motion";
var MotionDiv = chakra6(motion2.div);
var ModalOverlay = forwardRef7((props, ref) => {
  const { className, transition, motionProps: _motionProps, ...rest } = props;
  const _className = cx("chakra-modal__overlay", className);
  const styles = useModalStyles();
  const overlayStyle = {
    pos: "fixed",
    left: "0",
    top: "0",
    w: "100vw",
    h: "100vh",
    ...styles.overlay
  };
  const { motionPreset } = useModalContext();
  const defaultMotionProps = motionPreset === "none" ? {} : fadeConfig;
  const motionProps = _motionProps || defaultMotionProps;
  return /* @__PURE__ */ React.createElement(MotionDiv, {
    ...motionProps,
    __css: overlayStyle,
    ref,
    className: _className,
    ...rest
  });
});
ModalOverlay.displayName = "ModalOverlay";

// src/alert-dialog.tsx
import { forwardRef as forwardRef8 } from "@chakra-ui/system";
function AlertDialog(props) {
  const { leastDestructiveRef, ...rest } = props;
  return /* @__PURE__ */ React.createElement(Modal, {
    ...rest,
    initialFocusRef: leastDestructiveRef
  });
}
var AlertDialogContent = forwardRef8((props, ref) => /* @__PURE__ */ React.createElement(ModalContent, {
  ref,
  role: "alertdialog",
  ...props
}));

// src/drawer.tsx
import { createContext as createContext2 } from "@chakra-ui/react-context";
import { useTheme } from "@chakra-ui/system";
var [DrawerContextProvider, useDrawerContext] = createContext2();
var placementMap = {
  start: { ltr: "left", rtl: "right" },
  end: { ltr: "right", rtl: "left" }
};
function getDrawerPlacement(placement, dir) {
  var _a;
  if (!placement)
    return;
  return ((_a = placementMap[placement]) == null ? void 0 : _a[dir]) ?? placement;
}
function Drawer(props) {
  var _a;
  const {
    isOpen,
    onClose,
    placement: placementProp = "right",
    children,
    ...rest
  } = props;
  const theme = useTheme();
  const drawerStyleConfig = (_a = theme.components) == null ? void 0 : _a.Drawer;
  const placement = getDrawerPlacement(placementProp, theme.direction);
  return /* @__PURE__ */ React.createElement(DrawerContextProvider, {
    value: { placement }
  }, /* @__PURE__ */ React.createElement(Modal, {
    isOpen,
    onClose,
    styleConfig: drawerStyleConfig,
    ...rest
  }, children));
}

// src/drawer-content.tsx
import {
  chakra as chakra7,
  forwardRef as forwardRef9
} from "@chakra-ui/system";
import { Slide } from "@chakra-ui/transition";
var MotionDiv2 = chakra7(Slide);
var DrawerContent = forwardRef9((props, ref) => {
  const { className, children, motionProps, ...rest } = props;
  const { getDialogProps, getDialogContainerProps, isOpen } = useModalContext();
  const dialogProps = getDialogProps(rest, ref);
  const containerProps = getDialogContainerProps();
  const _className = cx("chakra-modal__content", className);
  const styles = useModalStyles();
  const dialogStyles = {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: "100%",
    outline: 0,
    ...styles.dialog
  };
  const dialogContainerStyles = {
    display: "flex",
    width: "100vw",
    height: "$100vh",
    position: "fixed",
    left: 0,
    top: 0,
    ...styles.dialogContainer
  };
  const { placement } = useDrawerContext();
  return /* @__PURE__ */ React.createElement(chakra7.div, {
    ...containerProps,
    className: "chakra-modal__content-container",
    __css: dialogContainerStyles
  }, /* @__PURE__ */ React.createElement(ModalFocusScope, null, /* @__PURE__ */ React.createElement(MotionDiv2, {
    motionProps,
    direction: placement,
    in: isOpen,
    className: _className,
    ...dialogProps,
    __css: dialogStyles
  }, children)));
});
DrawerContent.displayName = "DrawerContent";
export {
  AlertDialog,
  ModalBody as AlertDialogBody,
  ModalCloseButton as AlertDialogCloseButton,
  AlertDialogContent,
  ModalFooter as AlertDialogFooter,
  ModalHeader as AlertDialogHeader,
  ModalOverlay as AlertDialogOverlay,
  Drawer,
  ModalBody as DrawerBody,
  ModalCloseButton as DrawerCloseButton,
  DrawerContent,
  ModalFooter as DrawerFooter,
  ModalHeader as DrawerHeader,
  ModalOverlay as DrawerOverlay,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContextProvider,
  ModalFocusScope,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useAriaHidden,
  useDrawerContext,
  useModal,
  useModalContext,
  useModalStyles
};
