"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ONE_SEC = exports.EVENTS = void 0;
var EVENTS;
(function (EVENTS) {
    EVENTS[EVENTS["CONNECT"] = 0] = "CONNECT";
    EVENTS[EVENTS["GET_ADDRESS"] = 1] = "GET_ADDRESS";
    EVENTS[EVENTS["SIGN_TRANSACTION"] = 2] = "SIGN_TRANSACTION";
    EVENTS[EVENTS["SIGN_ALL_TRANSACTIONS"] = 3] = "SIGN_ALL_TRANSACTIONS";
    EVENTS[EVENTS["SIGN_MESSAGE"] = 4] = "SIGN_MESSAGE";
})(EVENTS = exports.EVENTS || (exports.EVENTS = {}));
exports.ONE_SEC = 1000;
