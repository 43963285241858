// ../../../react-shim.js
import React from "react";

// src/media-query.tsx
import { useTheme } from "@chakra-ui/system";
var getBreakpoint = (theme, value) => {
  var _a;
  return ((_a = theme == null ? void 0 : theme.breakpoints) == null ? void 0 : _a[value]) ?? value;
};
function useQuery(props) {
  const { breakpoint = "", below, above } = props;
  const theme = useTheme();
  const bpBelow = getBreakpoint(theme, below);
  const bpAbove = getBreakpoint(theme, above);
  let query = breakpoint;
  if (bpBelow) {
    query = `(max-width: ${bpBelow})`;
  } else if (bpAbove) {
    query = `(min-width: ${bpAbove})`;
  }
  return query;
}

// src/use-media-query.ts
import { useEnvironment } from "@chakra-ui/react-env";
import { useEffect, useState } from "react";
function useMediaQuery(query, options = {}) {
  const { ssr = true, fallback } = options;
  const env = useEnvironment();
  const queries = Array.isArray(query) ? query : [query];
  let fallbackValues = Array.isArray(fallback) ? fallback : [fallback];
  fallbackValues = fallbackValues.filter((v) => v != null);
  const [value, setValue] = useState(() => {
    return queries.map((query2, index) => ({
      media: query2,
      matches: ssr ? !!fallbackValues[index] : env.window.matchMedia(query2).matches
    }));
  });
  useEffect(() => {
    setValue(queries.map((query2) => ({
      media: query2,
      matches: env.window.matchMedia(query2).matches
    })));
    const mql = queries.map((query2) => env.window.matchMedia(query2));
    const handler = (evt) => {
      setValue((prev) => {
        return prev.slice().map((item) => {
          if (item.media === evt.media)
            return { ...item, matches: evt.matches };
          return item;
        });
      });
    };
    mql.forEach((mql2) => {
      if (typeof mql2.addListener === "function") {
        mql2.addListener(handler);
      } else {
        mql2.addEventListener("change", handler);
      }
    });
    return () => {
      mql.forEach((mql2) => {
        if (typeof mql2.removeListener === "function") {
          mql2.removeListener(handler);
        } else {
          mql2.removeEventListener("change", handler);
        }
      });
    };
  }, [env.window]);
  return value.map((item) => item.matches);
}

// src/media-query.hook.ts
function usePrefersReducedMotion(options) {
  const [prefersReducedMotion] = useMediaQuery("(prefers-reduced-motion: reduce)", options);
  return prefersReducedMotion;
}
function useColorModePreference(options) {
  const [isLight, isDark] = useMediaQuery(["(prefers-color-scheme: light)", "(prefers-color-scheme: dark)"], options);
  if (isLight)
    return "light";
  if (isDark)
    return "dark";
  return void 0;
}

// src/use-breakpoint.ts
import { useTheme as useTheme2 } from "@chakra-ui/system";

// ../../utilities/shared-utils/dist/index.esm.js
function isObject(value) {
  const type = typeof value;
  return value != null && (type === "object" || type === "function") && !Array.isArray(value);
}

// src/use-breakpoint.ts
function useBreakpoint(arg) {
  var _a;
  const opts = isObject(arg) ? arg : { fallback: arg ?? "base" };
  const theme = useTheme2();
  const breakpoints = theme.__breakpoints.details.map(({ minMaxQuery, breakpoint }) => ({
    breakpoint,
    query: minMaxQuery.replace("@media screen and ", "")
  }));
  const fallback = breakpoints.map((bp) => bp.breakpoint === opts.fallback);
  const values = useMediaQuery(breakpoints.map((bp) => bp.query), { fallback, ssr: opts.ssr });
  const index = values.findIndex((value) => value == true);
  return ((_a = breakpoints[index]) == null ? void 0 : _a.breakpoint) ?? opts.fallback;
}

// src/use-breakpoint-value.ts
import { useTheme as useTheme3 } from "@chakra-ui/system";
import { arrayToObjectNotation } from "@chakra-ui/breakpoint-utils";

// src/media-query.utils.ts
import { breakpoints as defaultBreakPoints } from "@chakra-ui/breakpoint-utils";
function getClosestValue(values, breakpoint, breakpoints = defaultBreakPoints) {
  let index = Object.keys(values).indexOf(breakpoint);
  if (index !== -1) {
    return values[breakpoint];
  }
  let stopIndex = breakpoints.indexOf(breakpoint);
  while (stopIndex >= 0) {
    const key = breakpoints[stopIndex];
    if (values.hasOwnProperty(key)) {
      index = stopIndex;
      break;
    }
    stopIndex -= 1;
  }
  if (index !== -1) {
    const key = breakpoints[index];
    return values[key];
  }
  return void 0;
}

// src/use-breakpoint-value.ts
function useBreakpointValue(values, arg) {
  var _a;
  const opts = isObject(arg) ? arg : { fallback: arg ?? "base" };
  const breakpoint = useBreakpoint(opts);
  const theme = useTheme3();
  if (!breakpoint)
    return;
  const breakpoints = Array.from(((_a = theme.__breakpoints) == null ? void 0 : _a.keys) || []);
  const obj = Array.isArray(values) ? Object.fromEntries(Object.entries(arrayToObjectNotation(values, breakpoints)).map(([key, value]) => [key, value])) : values;
  return getClosestValue(obj, breakpoint, breakpoints);
}

// src/visibility.tsx
function Visibility(props) {
  const { breakpoint, hide, children, ssr } = props;
  const [show] = useMediaQuery(breakpoint, { ssr });
  const isVisible = hide ? !show : show;
  const rendered = isVisible ? children : null;
  return rendered;
}

// src/show.tsx
function Show(props) {
  const { children, ssr } = props;
  const query = useQuery(props);
  return /* @__PURE__ */ React.createElement(Visibility, {
    breakpoint: query,
    ssr
  }, children);
}
Show.displayName = "Show";

// src/hide.tsx
function Hide(props) {
  const { children, ssr } = props;
  const query = useQuery(props);
  return /* @__PURE__ */ React.createElement(Visibility, {
    breakpoint: query,
    hide: true,
    ssr
  }, children);
}
Hide.displayName = "Hide";
export {
  Hide,
  Show,
  useBreakpoint,
  useBreakpointValue,
  useColorModePreference,
  useMediaQuery,
  usePrefersReducedMotion,
  useQuery
};
