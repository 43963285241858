// ../../../react-shim.js
import React from "react";

// src/index.ts
export * from "@chakra-ui/color-mode";
export * from "@chakra-ui/styled-system";
import { keyframes } from "@emotion/react";

// src/hooks.ts
import { useColorMode } from "@chakra-ui/color-mode";

// src/use-theme.ts
import { ThemeContext } from "@emotion/react";
import { useContext } from "react";
function useTheme() {
  const theme = useContext(ThemeContext);
  if (!theme) {
    throw Error("useTheme: `theme` is undefined. Seems you forgot to wrap your app in `<ChakraProvider />` or `<ThemeProvider />`");
  }
  return theme;
}

// src/hooks.ts
function useChakra() {
  const colorModeResult = useColorMode();
  const theme = useTheme();
  return { ...colorModeResult, theme };
}
function getBreakpointValue(theme, value, fallback) {
  if (value == null)
    return value;
  const getValue = (val) => {
    var _a, _b;
    return (_b = (_a = theme.__breakpoints) == null ? void 0 : _a.asArray) == null ? void 0 : _b[val];
  };
  return getValue(value) ?? getValue(fallback) ?? fallback;
}
function getTokenValue(theme, value, fallback) {
  if (value == null)
    return value;
  const getValue = (val) => {
    var _a, _b;
    return (_b = (_a = theme.__cssMap) == null ? void 0 : _a[val]) == null ? void 0 : _b.value;
  };
  return getValue(value) ?? getValue(fallback) ?? fallback;
}
function useToken(scale, token, fallback) {
  const theme = useTheme();
  return getToken(scale, token, fallback)(theme);
}
function getToken(scale, token, fallback) {
  const _token = Array.isArray(token) ? token : [token];
  const _fallback = Array.isArray(fallback) ? fallback : [fallback];
  return (theme) => {
    const fallbackArr = _fallback.filter(Boolean);
    const result = _token.map((token2, index) => {
      if (scale === "breakpoints") {
        return getBreakpointValue(theme, token2, fallbackArr[index] ?? token2);
      }
      const path = `${scale}.${token2}`;
      return getTokenValue(theme, path, fallbackArr[index] ?? token2);
    });
    return Array.isArray(token) ? result : result[0];
  };
}

// src/providers.tsx
import { useColorMode as useColorMode2 } from "@chakra-ui/color-mode";
import { createContext } from "@chakra-ui/react-utils";
import { css, toCSSVar } from "@chakra-ui/styled-system";
import { memoizedGet as get, runIfFn } from "@chakra-ui/utils";
import {
  Global,
  ThemeProvider as EmotionThemeProvider
} from "@emotion/react";
import { useMemo } from "react";
function ThemeProvider(props) {
  const { cssVarsRoot, theme, children } = props;
  const computedTheme = useMemo(() => toCSSVar(theme), [theme]);
  return /* @__PURE__ */ React.createElement(EmotionThemeProvider, {
    theme: computedTheme
  }, /* @__PURE__ */ React.createElement(CSSVars, {
    root: cssVarsRoot
  }), children);
}
function CSSVars({ root = ":host, :root" }) {
  const selector = [root, `[data-theme]`].join(",");
  return /* @__PURE__ */ React.createElement(Global, {
    styles: (theme) => ({ [selector]: theme.__cssVars })
  });
}
var [StylesProvider, useStyles] = createContext({
  name: "StylesContext",
  errorMessage: "useStyles: `styles` is undefined. Seems you forgot to wrap the components in `<StylesProvider />` "
});
function createStylesContext(componentName) {
  return createContext({
    name: `${componentName}StylesContext`,
    errorMessage: `useStyles: "styles" is undefined. Seems you forgot to wrap the components in "<${componentName} />" `
  });
}
function GlobalStyle() {
  const { colorMode } = useColorMode2();
  return /* @__PURE__ */ React.createElement(Global, {
    styles: (theme) => {
      const styleObjectOrFn = get(theme, "styles.global");
      const globalStyles = runIfFn(styleObjectOrFn, { theme, colorMode });
      if (!globalStyles)
        return void 0;
      const styles = css(globalStyles)(theme);
      return styles;
    }
  });
}

// src/system.ts
import { useColorMode as useColorMode3 } from "@chakra-ui/color-mode";
import {
  css as css2,
  isStyleProp
} from "@chakra-ui/styled-system";
import { filterUndefined, objectFilter, runIfFn as runIfFn2 } from "@chakra-ui/utils";
import emotionStyled from "@emotion/styled";
import React2 from "react";

// src/should-forward-prop.ts
import { propNames } from "@chakra-ui/styled-system";
var allPropNames = /* @__PURE__ */ new Set([
  ...propNames,
  "textStyle",
  "layerStyle",
  "apply",
  "noOfLines",
  "focusBorderColor",
  "errorBorderColor",
  "as",
  "__css",
  "css",
  "sx"
]);
var validHTMLProps = /* @__PURE__ */ new Set(["htmlWidth", "htmlHeight", "htmlSize"]);
function shouldForwardProp(prop) {
  return validHTMLProps.has(prop) || !allPropNames.has(prop);
}

// src/system.ts
var toCSSObject = ({ baseStyle }) => (props) => {
  const { theme, css: cssProp, __css, sx, ...rest } = props;
  const styleProps = objectFilter(rest, (_, prop) => isStyleProp(prop));
  const finalBaseStyle = runIfFn2(baseStyle, props);
  const finalStyles = Object.assign({}, __css, finalBaseStyle, filterUndefined(styleProps), sx);
  const computedCSS = css2(finalStyles)(props.theme);
  return cssProp ? [computedCSS, cssProp] : computedCSS;
};
function styled(component, options) {
  const { baseStyle, ...styledOptions } = options ?? {};
  if (!styledOptions.shouldForwardProp) {
    styledOptions.shouldForwardProp = shouldForwardProp;
  }
  const styleObject = toCSSObject({ baseStyle });
  const Component = emotionStyled(component, styledOptions)(styleObject);
  const chakraComponent = React2.forwardRef(function ChakraComponent(props, ref) {
    const { colorMode, forced } = useColorMode3();
    return React2.createElement(Component, {
      ref,
      "data-theme": forced ? colorMode : void 0,
      ...props
    });
  });
  return chakraComponent;
}

// src/forward-ref.tsx
import { forwardRef as forwardReactRef } from "react";
function forwardRef(component) {
  return forwardReactRef(component);
}

// src/use-style-config.ts
import {
  resolveStyleConfig
} from "@chakra-ui/styled-system";
import {
  filterUndefined as filterUndefined2,
  memoizedGet as get2,
  mergeWith,
  omit
} from "@chakra-ui/utils";
import { useRef } from "react";
import isEqual from "react-fast-compare";
function useStyleConfigImpl(themeKey, props = {}) {
  const { styleConfig: styleConfigProp, ...rest } = props;
  const { theme, colorMode } = useChakra();
  const themeStyleConfig = get2(theme, `components.${themeKey}`);
  const styleConfig = styleConfigProp || themeStyleConfig;
  const mergedProps = mergeWith({ theme, colorMode }, (styleConfig == null ? void 0 : styleConfig.defaultProps) ?? {}, filterUndefined2(omit(rest, ["children"])));
  const stylesRef = useRef({});
  if (styleConfig) {
    const getStyles = resolveStyleConfig(styleConfig);
    const styles = getStyles(mergedProps);
    const isStyleEqual = isEqual(stylesRef.current, styles);
    if (!isStyleEqual) {
      stylesRef.current = styles;
    }
  }
  return stylesRef.current;
}
function useStyleConfig(themeKey, props = {}) {
  return useStyleConfigImpl(themeKey, props);
}
function useMultiStyleConfig(themeKey, props = {}) {
  return useStyleConfigImpl(themeKey, props);
}

// src/factory.ts
function factory() {
  const cache = /* @__PURE__ */ new Map();
  return new Proxy(styled, {
    apply(target, thisArg, argArray) {
      return styled(...argArray);
    },
    get(_, element) {
      if (!cache.has(element)) {
        cache.set(element, styled(element));
      }
      return cache.get(element);
    }
  });
}
var chakra = factory();
export {
  CSSVars,
  GlobalStyle,
  StylesProvider,
  ThemeProvider,
  chakra,
  createStylesContext,
  forwardRef,
  getToken,
  keyframes,
  shouldForwardProp,
  styled,
  toCSSObject,
  useChakra,
  useMultiStyleConfig,
  useStyleConfig,
  useStyles,
  useTheme,
  useToken
};
