"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WalletConnector = exports.UID = void 0;
const web3_js_1 = require("@solana/web3.js");
const bridge_1 = require("./bridge");
const constants_1 = require("./constants");
const UID = () => Math.round(Math.random() * Math.pow(10, 9));
exports.UID = UID;
class WalletConnector {
    constructor(appId, verbose = false) {
        this.interact = ({ event, data = {}, timeout = constants_1.ONE_SEC * 3, }) => __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                try {
                    if (!window.parent)
                        throw new Error('Cannot access to parent window');
                    const timeoutId = setTimeout(() => reject('Request timeout'), timeout);
                    const id = this.id;
                    const uid = (0, exports.UID)();
                    const kill = this.messenger.listen(({ event: catchedEvent, uid: catchedUID, data }) => {
                        if (event === catchedEvent && uid === catchedUID) {
                            clearTimeout(timeoutId);
                            kill();
                            return resolve(data);
                        }
                    });
                    return this.messenger.emit(window.parent, { event, id, uid, data });
                }
                catch (er) {
                    return reject(er.message);
                }
            });
        });
        this.isConnected = () => __awaiter(this, void 0, void 0, function* () {
            return yield this.interact({
                event: constants_1.EVENTS.CONNECT,
                timeout: constants_1.ONE_SEC * 3,
            });
        });
        this.getAddress = () => __awaiter(this, void 0, void 0, function* () {
            return yield this.interact({
                event: constants_1.EVENTS.GET_ADDRESS,
                timeout: constants_1.ONE_SEC * 6,
            });
        });
        this.signTransaction = (transaction) => __awaiter(this, void 0, void 0, function* () {
            const serializedTx = yield this.interact({
                event: constants_1.EVENTS.SIGN_TRANSACTION,
                data: transaction.serialize({
                    requireAllSignatures: false,
                    verifySignatures: false,
                }),
                timeout: constants_1.ONE_SEC * 60,
            });
            const tx = web3_js_1.Transaction.from(serializedTx);
            return tx;
        });
        this.signAllTransactions = (transactions) => __awaiter(this, void 0, void 0, function* () {
            const serializedTxs = yield this.interact({
                event: constants_1.EVENTS.SIGN_ALL_TRANSACTIONS,
                data: transactions.map((transaction) => transaction.serialize({
                    requireAllSignatures: false,
                    verifySignatures: false,
                })),
                timeout: constants_1.ONE_SEC * 120,
            });
            const txs = serializedTxs.map((serializedTx) => web3_js_1.Transaction.from(serializedTx));
            return txs;
        });
        this.signMessage = (message) => __awaiter(this, void 0, void 0, function* () {
            const signedMessage = yield this.interact({
                event: constants_1.EVENTS.SIGN_MESSAGE,
                data: message,
                timeout: constants_1.ONE_SEC * 60,
            });
            return signedMessage;
        });
        this.id = appId + '-iframe';
        this.messenger = new bridge_1.Messenger({ name: this.id, verbose });
    }
}
exports.WalletConnector = WalletConnector;
