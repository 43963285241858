import React, { Suspense } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { createRoot } from 'react-dom/client'
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from 'react-router-dom'
import {
  ChakraProvider,
  extendTheme,
  Center,
  Flex,
  Image,
  Link,
  Button
} from '@chakra-ui/react'

import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter
} from '@solana/wallet-adapter-wallets'
import { SentreWalletAdapter } from '@sentre/connector'
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui'
import { clusterApiUrl } from '@solana/web3.js'

import mixpanel from 'mixpanel-browser'

import otterLogoFull from './icons/otterlogo_full.svg'

import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/600.css'
import '@fontsource/montserrat/700.css'
import '@fontsource/rock-salt/400.css'

// Default styles for Solana wallet adapter.
import './css/wallet-adapter-react-ui-styles.css'
// Extra styles / animations that cannot be defined inline.
import './css/custom.css'

const Homepage = React.lazy(() => import('./Homepage'))
const Application = React.lazy(() => import('./Application'))
mixpanel.init('68bd88a014a7b0d1049e25e45cb9dc35')

const IS_TEMP_LANDING_PAGE = false

const theme = extendTheme({
  colors: {
    peach: '#ffcd9e',
    champagne: '#f4e6ab',
    teagreen: '#e8ffb8',
    bistre: '#3f2f27',
    etonblue: '#90cba8',
    aero: '#84b4d9'
  },
  fonts: {
    button: 'Montserrat'
  },
  styles: {
    global: {
      body: {
        fontFamily: 'Montserrat',
        fontWeight: '400',
        color: 'bistre',
        bg: 'white',
        bgGradient: 'linear(150deg, peach, teagreen)',
        textAlign: 'center'
      },
      div: {
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap'
      }
    }
  },
  components: {
    Button: {
      baseStyle: {
      },
      variants: {
        fancy: {
          bgGradient: 'linear(150deg, etonblue, aero)',
          borderStyle: 'solid',
          borderColor: 'bistre',
          borderRadius: '10px',
          fontFamily: 'Rock Salt',
          fontWeight: '400'
        }
      }
    },
    Heading: {
      baseStyle: {
      },
      variants: {
        darkpoolHeading: {
          fontSize: '1.4em',
          m: ['10% 0 2% 0', '3% 0 1% 0']
        }
      }
    },
    Text: {
      baseStyle: {
      },
      variants: {
        darkpool: {
          m: ['4% 0 0 0', '1.5% 0 0 0']
        }
      }
    }
  }
})

window.Intercom('boot', {
  api_base: 'https://api-iam.intercom.io',
  app_id: 'c2vvqq3t'
})

interface CustomNavigateProps {
  to: string
}
class CustomNavigate extends React.Component<CustomNavigateProps> {
  componentDidMount () {
    window.open(this.props.to, '_self')
  }

  render () {
    return null
  }
}

mixpanel.track('Page View')
if (IS_TEMP_LANDING_PAGE) {
  createRoot(document.getElementById('root')).render(
    <React.StrictMode>
      <ChakraProvider theme={theme}>
        <Center>
          <Flex
            flexDirection="column"
            justifyContent="start"
            minH="100vh"
            w={['80%', '35%']}
            pt="15%"
          >
            <Image w="100%" src={otterLogoFull} />
            <Link
              href="https://twitter.com/OtterCashHQ"
              _hover={{ textDecoration: 'none' }}
              isExternal
              w={['80%', '50%']}
            >
              <Center pt="5%">
                <Button
                  w="80%"
                  p="8%"
                  backgroundColor="transparent"
                  border={['1px', '2px']}
                  borderColor="dark"
                  borderRadius="5px"
                  color="dark"
                  fontSize={['0.9em', '1.2em']}
                  _hover={{ bg: 'transparent' }}
                >
                  free your SOL
                </Button>
              </Center>
            </Link>
          </Flex>
        </Center>
      </ChakraProvider>
    </React.StrictMode>
  )
} else {
  const WalletWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const network = WalletAdapterNetwork.Devnet
    const endpoint = React.useMemo(() => clusterApiUrl(network), [network])
    const sentreAppId = 'otter_cash'
    const wallets = React.useMemo(
      () => [
        new PhantomWalletAdapter(),
        new SolflareWalletAdapter({ network }),
        new LedgerWalletAdapter(),
        new SentreWalletAdapter({ appId: sentreAppId }),
        new SolletExtensionWalletAdapter(),
        new SolletWalletAdapter(),
        new SlopeWalletAdapter(),
        new TorusWalletAdapter()
      ],
      [network]
    )
    return (
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            {children}
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    )
  }
  createRoot(document.getElementById('root')).render(
    <React.StrictMode>
      <WalletWrapper>
        <ChakraProvider theme={theme}>
          <HelmetProvider>
            <BrowserRouter>
              <Suspense fallback={null}>
                <Routes>
                  <Route path="/" element={
                    <>
                      <Helmet>
                        <title>Otter Cash</title>
                      </Helmet>
                      <Homepage />
                    </>
                  } />
                  <Route path="/deposit" element={
                    <>
                      <Helmet>
                        <title>Deposit | Otter Cash</title>
                      </Helmet>
                      <Application isDeposit={true} />
                    </>
                  } />
                  <Route path="/withdraw" element={
                    <>
                      <Helmet>
                        <title>Withdraw | Otter Cash</title>
                      </Helmet>
                      <Application isDeposit={false} />
                    </>
                  } />
                  <Route path="/darkpool" element={
                    <CustomNavigate to="https://renegade.fi/whitepaper.pdf" />
                  } />
                  <Route path="/darkpool.pdf" element={
                    <CustomNavigate to="https://renegade.fi/whitepaper.pdf" />
                  } />
                  <Route path="/whitepaper" element={
                    <CustomNavigate to="https://renegade.fi/whitepaper.pdf" />
                  } />
                  <Route path="/whitepaper.pdf" element={
                    <CustomNavigate to="https://renegade.fi/whitepaper.pdf" />
                  } />
                  <Route path="/*" element={<Navigate to="/" />} />
                </Routes>
              </Suspense>
            </BrowserRouter>
          </HelmetProvider>
        </ChakraProvider>
      </WalletWrapper>
    </React.StrictMode>
  )
}
